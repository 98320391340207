import logo from "./kemily-massoterapeuta.png";
import insta from "./insta.png";
import "./App.css";

const App = () => {
    return (
        <div className="App">
            <img className="App-logo" src={logo} alt="Kemily Oliveira massoterapeuta." />
            <p className="App-slogan">MASSOTERAPIA</p>
            <a href="https://www.instagram.com/kemilyosmassoterapia/" className="App-social" target="_blank" rel="noreferrer">
                <img src={insta} className="insta-logo" alt=""/>
                <span> /kemilyosmassoterapia</span>
            </a>
        </div>
    );
}

export default App;
